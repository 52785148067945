import EditNoteIcon from '@mui/icons-material/EditNote'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { Typography, Button } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { formatDateForUI } from '~/shared/lib/date'
import {
  Gate,
  $offer,
  fetchLatestPublicOfferFx,
  loadSignOffer,
  loadSignOfferFx,
} from './mode'
import { OfferWrapper, LoadingButton, Skeleton } from './styled'

export function DocumentOffer() {
  useGate(Gate)

  const offer = useStore($offer)
  const isFetchOfferPending = useStore(fetchLatestPublicOfferFx.pending)
  const isLoadSignOfferPending = useStore(loadSignOfferFx.pending)

  return (
    <div data-testid='document-offer-block'>
      <Typography fontSize={14} fontWeight={500}>
        Оферта
      </Typography>
      {isFetchOfferPending && <Skeleton />}

      {!isFetchOfferPending && !offer && (
        <Typography>Документов нет</Typography>
      )}

      {!isFetchOfferPending && offer && (
        <OfferWrapper data-testid='document-offer'>
          <Typography fontSize={12} data-testid='document-offer-date'>
            Дата {formatDateForUI(offer.getConfirmedAt())}
          </Typography>

          <div>
            <LoadingButton
              loading={isLoadSignOfferPending}
              variant='lightSteelBlueBorder'
              size='small'
              onClick={() => loadSignOffer()}
              endIcon={<EditNoteIcon />}
              sx={{ marginRight: '6px' }}
            >
              Подпись
            </LoadingButton>

            <Button
              variant='limeFlooded'
              size='small'
              href={offer?.getDocumentFileLink() as string}
              target='_blank'
              endIcon={<FileOpenIcon />}
              sx={{ padding: '4px 8px' }}
            >
              Оферта
            </Button>
          </div>
        </OfferWrapper>
      )}
    </div>
  )
}
