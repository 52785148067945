import { useEffect, useState } from 'react'
import { CmsBlockAttributes } from '~/shared/api'
import {
  Content,
  Img,
  Wrapper,
  CloseIcon,
  IonModal,
  ContentWrapper,
} from './styled'

type Story = CmsBlockAttributes & { id: UniqueId }

type StoryModalProps = {
  onClose: () => void
  storyId?: UniqueId
  storyList?: Story[]
}

export function StoryModal({
  onClose,
  storyId,
  storyList = [],
}: StoryModalProps) {
  const isShow = Boolean(storyId)
  const [story, setStory] = useState<Story>()

  useEffect(() => {
    if (storyId) {
      setStory(storyList.find((story) => story.id === storyId))
    }
  }, [storyId, storyList])

  if (!story) return null

  return (
    <IonModal
      isOpen={isShow}
      keepContentsMounted={false}
      presentingElement={
        document.getElementById('page-container') as HTMLElement
      }
      onDidDismiss={onClose}
      data-testid='story-modal'
    >
      <CloseIcon onClick={onClose} data-testid='story-modal-close-button' />
      <Wrapper>
        {story?.image?.url && (
          <Img url={story.image.url} alt={story.previewContent} />
        )}
        <ContentWrapper>
          <Content
            data-testid='story-modal-content'
            dangerouslySetInnerHTML={{ __html: story.content }}
          />
        </ContentWrapper>
      </Wrapper>
    </IonModal>
  )
}
