import * as z from 'zod'

import { AcceptanceDocumentTypeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  userId: z.string(),
  documentType: z.nativeEnum(AcceptanceDocumentTypeEnum),
  documentId: z.string().nullable(),
  documentDate: z.string(),
  documentFileLink: z.string().nullable(),
  confirmedAt: dateSchema,
  acceptanceFileLink: z.string().nullable(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type AcceptanceAttributes = z.infer<typeof attributeSchema>

export type AcceptFetchValues = Pick<
  AcceptanceAttributes,
  'documentType' | 'documentId'
>

export type ConfirmFetchValues = {
  acceptanceId: UniqueId
  confirmCode: string
  deviceInfo: string
}

export class Acceptance extends ApiModel<typeof schema, AcceptanceAttributes> {
  static jsonApiType = 'acceptance'

  static schema = schema

  getDocumentType(): AcceptanceDocumentTypeEnum {
    return this.getAttribute('documentType')
  }
  getConfirmedAt(): string {
    return this.getAttribute('confirmedAt')
  }
  getAcceptanceFileLink(): string {
    return this.getAttribute('acceptanceFileLink')
  }
  getDocumentFileLink(): string {
    return this.getAttribute('documentFileLink')
  }

  static async need() {
    const url = `${Acceptance.getJsonApiUrl()}/need`
    const response = await Acceptance.effectiveHttpClient.get(url)
    return response?.getData().data as AcceptanceAttributes[]
  }

  static async accept(values: AcceptFetchValues) {
    const url = `${Acceptance.getJsonApiUrl()}/accept`
    const res = await Acceptance.effectiveHttpClient.post(url, values)
    return res.getData().data
  }

  static async acceptList() {
    const url = `${Acceptance.getJsonApiUrl()}/list`
    const res = await Acceptance.effectiveHttpClient.get(url)
    return res.getData().data
  }

  static async latestPublicOffer(): Promise<Acceptance> {
    const url = `${Acceptance.getJsonApiUrl()}/latest-public-offer`
    const res = await Acceptance.effectiveHttpClient.get(url)
    const data = res.getData().data
    return new Acceptance(data, data.id)
  }

  static async confirm(values: ConfirmFetchValues) {
    const url = `${Acceptance.getJsonApiUrl()}/confirm`
    await Acceptance.effectiveHttpClient.patch(url, values)
  }

  static async publicOfferDocument(documentId: UniqueId): Promise<Blob> {
    const url = `${Acceptance.getJsonApiUrl()}/document-file/PublicOffer/${documentId}`
    const client = Acceptance.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }
}
