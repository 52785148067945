import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { Acceptance, AxiosErrorType, Media } from '~/shared/api'
import { loadingModel } from '~/shared/lib/loading'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { openBlobFile } from '~/shared/lib/openBlobFile'
import { isString } from '~/shared/lib/utils'

const domain = createDomain('document-offer')
export const Gate = createGate()

export const fetchLatestPublicOfferFx = domain.createEffect<void, Acceptance>({
  handler: () => {
    return Acceptance.latestPublicOffer()
  },
})

export const $offer = domain
  .createStore<Acceptance | null>(null)
  .on(fetchLatestPublicOfferFx.doneData, (_, accept) => accept)

sample({
  clock: Gate.open,
  target: fetchLatestPublicOfferFx,
})

// Sign offer File
export const loadSignOffer = domain.createEvent()
export const loadSignOfferFx = domain.createEffect<
  string,
  Blob,
  AxiosErrorType
>({
  handler: async (fileLink) => {
    return await Media.getMedia(fileLink)
  },
})

const openSignOfferFx = domain.createEffect<Blob, void>({
  handler: async (blob) => {
    await openBlobFile(blob, 'Подпись.pdf')
  },
})

sample({
  clock: loadSignOffer,
  source: $offer,
  filter: (offer) => isString(offer?.getAcceptanceFileLink()),
  fn: (offer) => offer?.getAcceptanceFileLink() as string,
  target: loadSignOfferFx,
})

sample({
  clock: loadSignOfferFx.pending,
  fn: () => ({
    message: 'Загрузка подписи',
  }),
  target: loadingModel.present,
})

sample({
  clock: loadSignOfferFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: loadSignOfferFx.finally,
  target: loadingModel.dismiss,
})

sample({
  clock: loadSignOfferFx.doneData,
  target: openSignOfferFx,
})

sample({
  clock: openSignOfferFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка, невозможно просмотреть подпись!',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})
