import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Typography } from '@mui/material'
import { subDays, format } from 'date-fns'
import { useStore, useStoreMap } from 'effector-react'
import React, { useEffect, useMemo, useState } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { balanceHistoriesModel } from 'src/entities/BalanceHistories'
import { CURRENCY } from '~/shared/config/constants'
import { formatForApi } from '~/shared/lib/date'
import { ProgressBlock } from '~/shared/ui/Loaders'

import { Wrapper, MainBlock, IconButton } from './staled'

const currentDate = new Date()

const createDate = (subDaysCount: number) => {
  return format(subDays(currentDate, subDaysCount), 'd MMMM. yyyy г')
}

const uiCurrentDate = createDate(0)

type BalanceCalendarHeadingProps = {
  onDateChange: (date: string) => void
}

export function BalanceCalendarHeading({
  onDateChange,
}: BalanceCalendarHeadingProps) {
  const [subDaysCount, setSubDaysCount] = useState<number>(0)
  const [movement, setMovement] = useState<string>('back')
  const ref = React.useRef(null)

  const isLoading = useStore(
    balanceHistoriesModel.getBalanceReportForDayFx.pending,
  )

  const profitAmount = useStoreMap(
    balanceHistoriesModel.$balanceReportForDay,
    (balanceReport) => balanceReport?.getProfitAmount() || 0,
  )
  const uiDate = useMemo(() => createDate(subDaysCount), [subDaysCount])

  const uiDateForApi = useMemo(
    () => formatForApi(subDays(currentDate, subDaysCount)),
    [subDaysCount],
  )
  const isDisabledButtonNext = uiDate === uiCurrentDate

  useEffect(() => {
    onDateChange(uiDateForApi)
  }, [onDateChange, uiDateForApi])

  const handleBackMovement = () => {
    setMovement('back')
    const timeout = setTimeout(() => {
      setSubDaysCount((prev) => prev + 1)
      clearTimeout(timeout)
    }, 0)
  }
  const handleNextMovement = () => {
    setMovement('next')
    const timeout = setTimeout(() => {
      setSubDaysCount((prev) => prev - 1)
      clearTimeout(timeout)
    }, 0)
  }

  return (
    <Wrapper>
      <IconButton onClick={handleBackMovement} color='aliceBlue'>
        <ChevronLeftIcon />
      </IconButton>

      <MainBlock>
        <SwitchTransition mode='out-in'>
          <CSSTransition
            key={uiDate}
            nodeRef={ref}
            timeout={200}
            unmountOnExit
            classNames={`fade-${movement}`}
          >
            <Box key={uiDate} ref={ref}>
              <Typography mb='24px' fontSize={16}>
                {uiDate}
              </Typography>

              {isLoading ? (
                <ProgressBlock height={48} size={24} />
              ) : (
                <Typography
                  fontSize={32}
                  fontWeight={500}
                  data-testid='balance-calendar-profit-amount'
                >
                  {profitAmount.toLocaleString()} {CURRENCY}
                </Typography>
              )}
            </Box>
          </CSSTransition>
        </SwitchTransition>

        <Typography fontSize={12}>Заработок за день</Typography>
      </MainBlock>

      <IconButton
        onClick={handleNextMovement}
        disabled={isDisabledButtonNext}
        color='aliceBlue'
      >
        <ChevronRightIcon />
      </IconButton>
    </Wrapper>
  )
}
