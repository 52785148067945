import { Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { $isDocumentsDrawerOpen, closeDocumentsDrawer } from './model'
import { DocumentOffer } from './ui'

export function Documents() {
  const isOpen = useStore($isDocumentsDrawerOpen)

  return (
    <DrawerComponent isShow={isOpen} onCancel={closeDocumentsDrawer}>
      <Typography fontSize={18} fontWeight={700} mb='16px'>
        Документы
      </Typography>

      <DocumentOffer />
    </DrawerComponent>
  )
}
