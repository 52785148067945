import { Box, Divider, Typography } from '@mui/material'
import { useCallback } from 'react'
import { BalanceHistory } from '~/shared/api'
import { MoveFundsType } from '~/shared/config/enums'
import { formatDateForUI, formatTimeForUI } from '~/shared/lib/date'
import { Card, EmptyList } from '~/shared/ui/BalanceList'
import {
  FetchFn,
  Item,
  SCROLLABLE_LIST_META_PAGE,
  ScrollableList,
} from '~/shared/ui/ScrollableList'
import { WrapperCard } from './styled'

type Row = Item & {
  createdAt: string
  comment: string
  amount: number
  description: string
  moveFundsType: MoveFundsType
}

export function ListBalance() {
  const fetchRows = useCallback<FetchFn<Row>>(async (page, pageSize) => {
    const builder = BalanceHistory.limit(pageSize).where(
      'type',
      'bank_operation',
    )

    const response = await builder.get(page)
    const data = response.getData()

    const items = data.map((balance) => {
      const createdAt = balance.getCreatedAt()
      return {
        id: balance?.getApiId() as string,
        createdAt,
        comment: balance?.getComment(),
        moveFundsType: balance?.getMoveFundsType(),
        amount: balance.getAmount(),
        description: createdAt ? formatTimeForUI(createdAt) : '',
      }
    })

    const httpClientResponse = response.getHttpClientResponse()
    const axiosResponse = httpClientResponse?.getUnderlying()
    const { to, currentPage, lastPage } =
      axiosResponse?.data?.meta?.page || SCROLLABLE_LIST_META_PAGE

    return {
      items,
      to,
      currentPage,
      lastPage,
    }
  }, [])

  const handleMapItems = useCallback((items: Row[]) => {
    const groupedData = Object.entries(
      items.reduce((data: Record<string, Row[]>, item) => {
        const dateKey = formatDateForUI(item.createdAt)

        if (!data[dateKey]) {
          data[dateKey] = []
        }

        data[dateKey].push(item)
        return data
      }, {}),
    )
    return (
      <>
        {groupedData.map(([date, items]) => (
          <WrapperCard key={date}>
            <Typography fontSize={14} color='mutedText.main' pb='16px'>
              {date}
            </Typography>
            {items.map(({ id, ...data }) => (
              <Box key={id} sx={{ mb: '16px' }}>
                <Card {...data} id={id} />
              </Box>
            ))}
            <Divider />
          </WrapperCard>
        ))}
      </>
    )
  }, [])

  return (
    <ScrollableList
      fetchFn={fetchRows}
      empty={<EmptyList />}
      mapItems={handleMapItems}
      skeletonHeight={58}
    />
  )
}
