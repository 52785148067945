import { ImageList as MuiImageList } from '@mui/material'
import { CmsBlockAttributes } from '~/shared/api'
import { Img } from '~/shared/ui/Img'
import { SkeletonsList } from './SkeletonsList'
import { Title, ImageListItem } from './styled'

type ListProps = {
  onClick: (storyId: UniqueId) => void
  storyList?: (CmsBlockAttributes & { id: UniqueId })[]
  isLoading: boolean
}

export function PreviewList({ onClick, storyList, isLoading }: ListProps) {
  return (
    <MuiImageList cols={2} gap={16}>
      {isLoading && <SkeletonsList />}

      {!isLoading &&
        (storyList || []).map((story) => (
          <ImageListItem
            key={story.id}
            onClick={() => onClick(story.id)}
            data-testid={`story-preview-${story.id}`}
            sx={{ aspectRatio: '1/1' }}
          >
            <Title>{story.previewContent}</Title>
            {story.previewImage?.url && (
              <Img
                url={story.previewImage?.url}
                alt={story.previewContent}
                loading='lazy'
              />
            )}
          </ImageListItem>
        ))}
    </MuiImageList>
  )
}
