import { Directory } from '@capacitor/filesystem'
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener'
import write_blob from 'capacitor-blob-writer'
import { isMobileApp } from '~/shared/config/env'

export const openBlobFile = async (blob: Blob, path: string) => {
  if (isMobileApp) {
    const filePath = await write_blob({
      path,
      blob,
      directory: Directory.Cache,
    })
    const fileOpenerOptions: FileOpenerOptions = {
      filePath: filePath,
    }

    await FileOpener.open(fileOpenerOptions)
  } else {
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }
}
