import { Box } from '@mui/material'
import { Option } from 'coloquent/dist/Option'
import { useCallback, useEffect, useState } from 'react'
import { balanceHistoriesModel } from '~/entities/BalanceHistories'
import { BalanceHistory } from '~/shared/api'
import { MoveFundsType } from '~/shared/config/enums'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Card, EmptyList } from '~/shared/ui/BalanceList'
import { ButtonGroup } from '~/shared/ui/ButtonGroup'
import {
  FetchFn,
  SCROLLABLE_LIST_META_PAGE,
  ScrollableList,
  Item,
} from '~/shared/ui/ScrollableList'
import { useFilters } from './useFilters'

type Row = Item & {
  comment: string
  amount: number
  description: string
  moveFundsType: MoveFundsType
}

type ListBalanceHistoriesProps = {
  date: string
}

export function ListBalanceHistories({ date }: ListBalanceHistoriesProps) {
  const [report, setReport] = useState<MoveFundsType>()
  const filters = useFilters()

  useEffect(() => {
    balanceHistoriesModel.getBalanceReportForDay(date)
  }, [date])

  const handleReportChange = useCallback((key?: MoveFundsType) => {
    setReport(key)
  }, [])

  const fetchRows = useCallback<FetchFn<Row>>(
    async (page, pageSize, filters) => {
      let builder = BalanceHistory.limit(pageSize).where('type', 'main')

      filters?.forEach(({ key, value }) => {
        if (key === 'applied') {
          builder
            .getQuery()
            .addOption(new Option('filter[appliedAtFrom]', value))
          builder.getQuery().addOption(new Option('filter[appliedAtTo]', value))

          return
        }
        if (key === 'moveFundsType') {
          if (value) {
            builder = builder.where(key, value)
          }
        }
      })

      const response = await builder.get(page)
      const data = response.getData()

      const items = data.map((balance) => {
        const createdAt = balance.getCreatedAt()
        return {
          id: balance?.getApiId() as string,
          comment: balance?.getComment(),
          moveFundsType: balance?.getMoveFundsType(),
          amount: balance.getAmount(),
          description: createdAt ? formatDateTimeForUI(createdAt) : '',
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const { to, currentPage, lastPage } =
        axiosResponse?.data?.meta?.page || SCROLLABLE_LIST_META_PAGE

      return {
        items,
        to,
        currentPage,
        lastPage,
      }
    },
    [],
  )

  const handleMapItems = useCallback(
    (items: Row[]) => (
      <>
        {items.map(({ id, ...data }) => (
          <Box mb='24px' key={id}>
            <Card {...data} id={id} />
          </Box>
        ))}
      </>
    ),
    [],
  )

  return (
    <>
      <Box sx={{ mb: '24px' }}>
        <ButtonGroup options={filters} onChange={handleReportChange} />
      </Box>
      <ScrollableList
        externalFilter={[
          { key: 'applied', value: date },
          { key: 'moveFundsType', value: report || '' },
        ]}
        fetchFn={fetchRows}
        empty={<EmptyList />}
        mapItems={handleMapItems}
        skeletonHeight={58}
      />
    </>
  )
}
