import { Typography, Box } from '@mui/material'
import { CURRENCY } from '~/shared/config/constants'
import { MoveFundsType } from '~/shared/config/enums'
import { Wrapper, DataWrapper } from './styled'

type CardProps = {
  comment: string
  amount: number
  description?: string
  moveFundsType: MoveFundsType
  id: UniqueId
}

export function Card({
  comment,
  description,
  amount,
  moveFundsType,
  id,
}: CardProps) {
  return (
    <Wrapper data-testid={`card-${id}`}>
      <DataWrapper>
        <Typography
          color='midnightBlue.main'
          mb='4px'
          fontWeight={700}
          fontSize={16}
          lineHeight='16px'
          data-testid='card-comment'
        >
          {comment}
        </Typography>
        {description && (
          <Typography
            color='mutedText.main'
            fontSize={14}
            data-testid='card-description'
          >
            {description}
          </Typography>
        )}
      </DataWrapper>
      <Box>
        <Typography
          fontSize={18}
          fontWeight={700}
          noWrap
          textAlign='right'
          data-testid='card-amount'
        >
          {moveFundsType === MoveFundsType.COMING ? '+' : '-'}
          {amount.toLocaleString()}&#x20;
          <Typography
            component='span'
            fontSize={16}
            color='midnightBlue.main'
            fontWeight={700}
          >
            {CURRENCY}
          </Typography>
        </Typography>
      </Box>
    </Wrapper>
  )
}
