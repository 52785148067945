export function MenuDocumentsIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#021542' />
      <path
        d='M13.8351 25.5C13.3935 25.5 12.9699 25.3244 12.6576 25.0118C12.3454 24.6993 12.1699 24.2754 12.1699 23.8333V10.5H19.6633L23.8263 14.6667V23.8333C23.8263 24.2754 23.6509 24.6993 23.3386 25.0118C23.0263 25.3244 22.6028 25.5 22.1611 25.5H13.8351Z'
        stroke='#E4ECFF'
        strokeWidth='1.28577'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8301 10.5V15.5H23.8257'
        stroke='#E4ECFF'
        strokeWidth='1.28577'
        strokeLinejoin='round'
      />
      <path
        d='M15.502 18.8337H20.4976M15.502 22.1671H20.4976'
        stroke='#E4ECFF'
        strokeWidth='1.28577'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
